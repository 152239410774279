/* MESSY CSS */


.info-card {
    padding-top: 10rem;
  }
  
  .white-bg{
    background-color: white !important;
  }
  
  .modal-mobile{
    padding-top:0px;
    padding-bottom:0px;
    font-size:85%;
  }
  
  .hr-s{
    border: 0;
    clear: both;
    display: block;
    background-color: #252525;
    height: 1px;
  }
  
  .popupregister{
    background: transparent;
    border: none;
  }
  
  .background{
    background-color: #f5f5f5;
  }
  
  .white-background{
    background-color: #ffffff;
  }
  
  .fa-envelope{
    font-size: 1.5rem;
  }
  
  .accent-color{
    color: #f4806c;
  }
  
  .modal-head{
    font-size: 1.2rem !important
  }
  
  .txt1{
    font-size: 2.5rem; font-weight: bold; margin-bottom: 0;
  }
  
  .txt2{
    font-size: 1.4rem; word-spacing: 0.1rem;
  }
  
  .text3{
    font-size: 1.2rem !important;
  }
  
  .grayfont{
    color: #44484c;
  }
  
  .bruneimap{
    width:23rem;
    height:15rem;
    margin-top: 7rem;
  }
  
  .mtop{
    margin-top: 2.5rem;
  }
  
  .mtop1{
    margin-top: 1.5rem;
    background-color: transparent
  }
  
  .logo-grid{
    white-space: nowrap;
    display:table;
    margin-left: 1rem;
    cursor: pointer;
  }
  
  .btn-image-size{
    min-width: 2rem;
    height: 48px;
  }
  
  .header-mobile {
    display:table-cell;
    vertical-align:middle;
    font-size: 21px; color: #333a40
  }
  
  .shortfoot {
    margin-bottom:420px;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }

/* WELCOME PAGE */

html, body {
    padding-top: 0rem;
    margin:0;
    padding:0;
    height:100%; /* needed for container min-height */
  }
  
  body > .ember-view {
    height: 100%;
  }
  
  .checkout-container {
    // source: https://stackoverflow.com/a/14101451 https://stackoverflow.com/a/25249
    /* Firefox */
    height: -moz-calc(100% - 54px);
    /* WebKit */
    height: -webkit-calc(100% - 54px);
    /* Opera */
    height: -o-calc(100% - 54px);
    /* Standard */
    height: calc(100% - 54px);
  }
  
  a {
    color: #343a40;
  }
  
  .bruh {
    background-color: white;
  }
  
  .input-bg-color {
    background-color: #f5f5f5;
  }
  
  .container-card-brown {
    // box-shadow: 5px 5px 15px #e2e2e2;
    text-align: left;
    border-radius: 1.25rem !important;
    border-top: 10px solid #E8C192 !important
  }
  
  .container-card {
    // box-shadow: 5px 5px 15px #e2e2e2;
    text-align: left;
    border-radius: 1.25rem !important;
    border-top: 10px solid #E8A892 !important
  }
  
  .btn-light-orange-color {
    display: inline-block;
    font-weight: 400;
    color: white;
    text-align: center;
    border-radius: 1.25rem !important;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #f4806c;
  }
  
  .btn-arrow {
    display: inline-block;
    font-weight: 400;
    color: black;
    text-align: center;
    border-radius: 1.25rem !important;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
  }
  
  .btn-light-blue-color {
    display: inline-block;
    font-weight: 400;
    color: white;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #E8A892;
  }
  
  .footer a {
    font-weight: bold;
  }
  
  .container {
    margin-top: 80px;
  }
  
  .navbar {
    color: black;
    border-bottom: solid #e8e8e8f5 1px;
  }
  
  .content {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  .infocontent {
    padding-left: 3rem;
  }
  
  .newscontent {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  .item {
    text-align: center;
  }
  
  .float1 {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 180px;
    right: 40px;
    background-color: #007bff;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
  }
  
  .float2 {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 110px;
    right: 40px;
    background-color: #007bff;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
  }
  
  .float3 {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #007bff;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
  }
  
  
  .my-float {
    margin-top: 22px;
  }
  
  
  @media (min-width: 768px) {
    h5 {
      font-size: 18px;
    }
  
    .btn-green {
      background-color: white;
      color: #01342f;
    }
  
    .btn-login {
      background-color: white;
    }
  
    .footer a {
      color: white;
    }
  
    .footer {
      background-color: #f4806c;
      color: white;
      margin-bottom: 0px !important;
      margin-left: 0px !important;
      padding-right: 2rem !important;
    }
  
    .bordered {
      border: 5px solid;
      border-radius: 15px;
      border-color: #01342f;
    }
  
    .welcome-title {
      font-size: 2rem !important;
    }
  }
  
  @media (min-width: 992px) {
    .container {
      max-width: 992px;
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .footer {
      background-color: #f4806c;
      color: white;
      margin-bottom: 0px !important;
      margin-left: 0px !important;
      padding-right: 4rem !important;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px;
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  
  
  
  @media (max-width: 767px) {
    h5 {
      font-size: 15px;
    }
  
    .disclaimer {
      border-top: 2px solid gray;
    }
  
    .copyright {
      font-size: 12px;
    }
  
    .btn-login {
      background-color: #01342f;
    }
  
    .btn-green {
      background-color: #01342f;
      color: white;
    }
  
    .btn-mobile {
      margin-top: 25px;
      border-radius: 35px;
    }
  }
  
  .form-login-rounded {
    border-radius: 0.5rem;
    border: none;
  }
  
  @media (max-width: 510px) {
  
    .nav-mobile {
      height: 6rem !important;
    }
  
    .info-card {
      padding-top: 4rem;
    }
  
    .shortfoot {
      margin-bottom:120px;
    }
  
    #surveylogo {
      margin-left: 0px !important;
    }
  
    .img-mobile {
      width: 3rem !important;
    }
  
    .header-mobile {
      font-size: 16px !important;
    }
  
    #dropdownMenuLink {
      padding: 0px !important;
      float: right !important;
      background-color: white !important;
      color: #505050 !important;
    }
  
    h5 {
      font-size: 13px !important;
    }
  
  
    .img-geoportal-mobile {
      margin-top: 6.5rem !important;
    }
  
    .main-mobile {
      margin-left: 0px !important;
      margin-top: 0px !important;
    }
  
    .item-title {
      padding: 5px 5px 0 5px !important;
    }
  
    .icon-size {
      font-size: 3em !important;
      padding-bottom: 0px !important;
      margin-top: 0px !important;
      padding-top: 0.5rem !important;
    }
  
    i.fa.fa-2x.fa-arrow-right {
      display: none !important;
    }
  
    i.fa.fa-2x.fa-arrow-left {
      display: none !important;
    }
  
    .container-fluid.col-11 {
      margin-bottom: 0rem !important;
      margin-top: 0rem !important;
      margin-left: 1.2rem !important;
    }
  
    .btn-choose {
      display: inline-block;
      font-weight: 400;
      color: #007bff;
      width: 90%;
      text-align: center;
      border-radius: 1.25rem !important;
      vertical-align: middle;
      user-select: none;
      background-color: transparent;
      border: 1px solid #007bff;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  
    .modal-responsive {
      margin: auto !important;
      padding: 0.6rem !important;
    }
  
    .icon-responsive {
      margin-left: 0px !important;
      font-size: 1.5rem !important;
    }
  
    .modal-dialog.modal-dialog-centered {
      margin-left: 0 !important;
    }
  }
  
  @media (max-width: 1024px) {
    .infocontent {
      padding-left: 0px !important;
    }
  
    .welcome-title {
      // display: none !important;
    }
  
    .ipad {
      flex: 0 0 41.6666666667% !important;
      max-width: 41.6666666667% !important;
    }
  
    .ipad-btn-login {
      margin-right: 4rem !important;
      background-color: transparent;
    }
  
    .ipad-login-title {
      font-size: 0.75rem !important;
      top: 2.45rem !important;
      left: -2.8rem !important;
    }
  
    .item-title {
      padding: 10px 5px 0 5px !important;
    }
  
    .icon-size {
      font-size: 3em !important;
      padding-bottom: 0px !important;
      margin-top: 0px !important;
      padding-top: 0.5rem !important;
    }
  }
  
  @media (max-width: 768px) {
    .ipad-btn-login {
      margin-right: 0rem !important;
      margin-left: 0rem !important;
    }
  
    .ipad-login-title {
      font-size: 0.75rem !important;
      top: 1.45rem !important;
      left: 0.2rem !important;
    }
  
    .form-group.form-inline {
      justify-content: flex-start !important;
    }
  
    .item-title {
      padding: 10px 5px 0 5px !important;
      font-size: 0.9rem !important;
    }
  
    .icon-size {
      font-size: 2.7em !important;
      padding-bottom: 0px !important;
      margin-top: 0px !important;
      padding-top: 0.5rem !important;
    }
  }
  
  .under-cons {
    padding: 4rem;
    border-radius: 10px;
    font-weight: bold;
    text-align: center;
    // border: 1px solid #f4806c;
    -webkit-box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.25);
  }
  
  .desc2 {
    font-size: 1.25rem;
    color: #E8A892;
    font: italic !important;
  }
  
  .under-cons .fa-wrench {
    font-size: 5rem !important;
    color: #E8A892;
  }
  
  .under-cons .desc {
    text-transform: uppercase;
    color: #E8A892;
    font-size: 3rem;
    font-weight: bold;
  }
  
  .backhome {
    background-color: #E8A892;
    position: absolute;
    height: 50px;
    bottom: 0;
    right: 0;
    padding: 15px;
    font-weight: bold;
  }
  
  .backhome a {
    text-decoration: none;
    color: white;
  }
  
  .backhome a:hover {
    color: #ca4b34;
  }
  
  .gp-card {
    text-align: left;
    border-radius: 1.25rem !important;
    // border-top: 10px solid #E8A892 !important
    border: 0.5px solid #DCDCDC !important ;
    font-family: 'Nunito Sans';
    // box-shadow: 5px 5px #F5F5F5;
    line-height: 0.5 !important;
  }
  
  .btn-size {
    width: 150px;
    height: 40px;
  }
  
  .bg-color {
    background-image: url("/images/brunei map.png");
    background-repeat: no-repeat;
    background-position: center center; 
    background-size: cover;
  }
  
  .gp-icon {
    max-width: 150px;
    max-height: 150px;
  }
  
  .pointer {
    cursor: pointer;
  }
  