
.content-carousel{
  margin-top: 7rem;
  border-top: solid #e8e8e8f5 1px;
  margin-bottom: 0px !important;
}

.carousel-text{
  font-size: 13px;
}

.container-carousel-item{
  min-height: 220px;
  max-width: 300px;
  border-radius: 2.2rem;
}

#carousel-icon-hover {
  text-decoration: none;
}

#carousel-icon-hover:hover {
  color: #E8A892 !important;
}

@media (min-width: 768px) {
	.owl-carousel {
	padding-left: 0 !important;
	margin-left: 0 !important;
	}
}

@media (min-width: 992px) {
	.owl-carousel {
	padding-left: 0 !important;
	margin-left: 0 !important;
	}
}

@media (max-width: 767px) {
	.owl-carousel .prev-slide {
	  background-color: #01342f;
	  left: -33px;
	}

	.owl-carousel .next-slide {
	  background-color: #01342f;
	  right: -33px;
	}

	.owl-carousel .prev-slide:hover {
	  background-position: 0px -53px;
	}

	.owl-carousel .next-slide:hover {
	  background-position: -24px -53px;
	}

	.owl-dots {
	  display: none;
	}
}

@media (max-width: 510px) {
	.content.row.content-carousel {
	background-color: white !important;
	margin-bottom: 0px !important;
	}

	.content-carousel {
	margin-top: 1.2rem !important;
	}

	.container-carousel-item {
	// box-shadow: 5px 5px 15px #e2e2e2;
	border-radius: 1.25rem !important;
	max-width: 250px !important;
	min-height: 125px !important;
	padding-bottom: 0px !important;
	padding-top: 1rem !important;
	// border-top: 5px solid #f4806c !important
	}
}

@media (max-width: 1024px) {
	.container-carousel-item {
	// box-shadow: 5px 5px 15px #e2e2e2;
	border-radius: 1.25rem !important;
	max-width: 250px !important;
	min-height: 140px !important;
	padding-bottom: 0px !important;
	padding-top: 1rem !important;
	// border-top: 5px solid #f4806c !important
	}
}

@media (max-width: 768px) {
	.container-carousel-item {
	// box-shadow: 5px 5px 15px #e2e2e2;
	border-radius: 1.25rem !important;
	max-width: 250px !important;
	min-height: 140px !important;
	padding-bottom: 0px !important;
	padding-top: 1rem !important;
	// border-top: 5px solid #f4806c !important
	}
}