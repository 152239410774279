$border-color: #DADADA;

.ember-light-table {
  width: 95%;
  margin: 0 auto;
  border-collapse: collapse;
  font-family: 'Open Sans', sans-serif;

  .multi-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  tfoot .lt-column {
    border-top: 1px solid $border-color;
  }

  thead .lt-column {
    border-bottom: 1px solid $border-color;
  }

  thead th,
  tfoot th {
    &.is-dragging {
      opacity: 0.75;
      background: #eee;
    }

    &.is-drag-target {
      &.drag-right {
        border-right: 1px dotted $border-color;
      }

      &.drag-left {
        border-left: 1px dotted $border-color;
      }
    }
  }

  .lt-column {
    font-weight: 200;
    font-size: 12px;
    padding: 10px;

    .lt-sort-icon {
      width: 15px;
    }

    &.lt-group-column {
      border: none !important;
      padding-bottom: 10px;
    }

    .lt-column-resizer {
      border-right: 1px dashed #ccc;
      border-left: 1px dashed #ccc;
    }
  }

  .lt-row {
    height: 50px;

    &.is-selected {
      background-color: #DEDEDE !important;
    }

    &:not(.is-selected):hover {
      background-color: #F5F4F4 !important;
    }

    &:last-of-type {
      td {
        border-bottom-width: 0;
      }
    }

    &.lt-expanded-row, &.lt-no-data {
      &:hover {
        background-color: transparent !important;
      }
      td {
        padding: 15px;
      }
    }

    td {
      border-color: $border-color;
      border-width: 0;
      border-bottom-width: 1px;
      border-style: solid;
      font-size: 13px;
      padding: 0 10px;
    }
  }
}

tfoot {
  tr > td {
    border-top: 1px solid #DADADA;
    padding: 10px 10px 0 10px;
    font-size: 13px;
  }

  form {
    display: flex;
    justify-content: space-between;
  }
}


.rTable {
  display: table;
  width: 100%;
}
.rTableRow {
  display: table-row;
}
.rTableHeading {
  display: table-header-group;
  background-color: #ddd;
}
.rTableCell, .rTableHead {
  display: table-cell;
  padding: 3px 10px;
  border: 1px solid #999999;
}
.rTableHeading {
  display: table-header-group;
  background-color: #ddd;
  font-weight: bold;
}
.rTableFoot {
  display: table-footer-group;
  font-weight: bold;
  background-color: #ddd;
}
.rTableBody {
  display: table-row-group;
}

.popover-table {
  font-size: 0.7rem;
  line-height: 1;
}