@import "./table.scss";
@import "./ol-layer-tree.scss";
@import "./loading-cover";

.gp-title {
    color: white; 
    font-weight: bold; 
    text-decoration: none;
}

.scale-modal h4 {
    color: black;
    // font-family: Georgia, serif;
    font-weight: 600;
    line-height: 2;
}

.btn-info-shadow {
    box-shadow: 5px 5px #17a2b8;
}

.nlump-modal h4 {
    color: black;
    // font-family: Georgia, serif;
    font-weight: 600;
    line-height: 2;
}

.ol-map-outer-container {
  position: relative;
  height: 600px;
  margin: 25px 0px 25px 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.gp-popup {
  width: 40vw;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .gp-popup {
    width: 70vw;
    max-width: 100%;
  }
}

.legend_mp {
  border: none;
  height: 3px;
}

.tooltip-right:hover + .tooltiptext {
  display: block;
  position: absolute;
}
.tooltip-left:hover + .tooltiptext {
  display: block;
  position: absolute;
  right: 20px;
}
.tooltip-center:hover + .tooltiptext {
  display: block;
  position: absolute;
  left: 0;
}

.tooltiptext {
  display: none;
}

#tools {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
#tools a {
  display: inline-block;
  padding: 0.5rem;
  background: white;
  cursor: pointer;
}


.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.ol-layer-tree {
  position: absolute;
  background-color: #eaf1f1;
  top: 10px;
  right: 10px;
  padding: 5px;
  padding-left: 10px;
  border: 1px solid #999999;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ol-map-panel {
  position: absolute;
  /* border: 1px solid #999999; */
  /* border-radius: 5px; */
  /* top: 10px; */
  /* bottom: 10px; */
  /* left: 320px; */
  /* right: 10px; */
  width: 100%; // hc
  height: calc(100vh - 48px); // hc
  background-color: black;
}

.ol-map-panel-gp {
    position: absolute;
    /* border: 1px solid #999999; */
    /* border-radius: 5px; */
    /* top: 10px; */
    /* bottom: 10px; */
    /* left: 320px; */
    /* right: 10px; */
    width: 100%; // hc
    height: calc(100vh - 48px); // hc
    background-color: white;
}

.feature-table {
  position: absolute;
  border: 1px solid #999999;
  border-radius: 5px;
  top: 10px;
  bottom: 10px;
  left: 10px;
  width: 300px;
}

.ol-control,
.ol-scale-line {
  position: absolute;
  padding: 2px
}

.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 2px solid #00f
}

.ol-mouse-position {
  top: 8px;
  right: 8px;
  position: absolute
}

.ol-scale-line {
  background: #95b9e6;
  background: rgba(0, 60, 136, .3);
  border-radius: 4px;
  bottom: 10em;
  right: 0.5em;
}

.ol-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 10px;
  text-align: center;
  margin: 1px;
  will-change: contents, width
}

.ol-overlay-container {
  will-change: left, right, top, bottom
}

.ol-unsupported {
  display: none
}

.ol-viewport .ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent
}

.ol-control {
  background-color: #eee;
  background-color: rgba(255, 255, 255, .4);
  border-radius: 4px
}

.ol-control:hover {
  background-color: rgba(255, 255, 255, .6)
}

.ol-zoom {
  top: 1.0em !important;
  right: .5em !important;
}

.ol-rotate {
  top: .5em;
  right: .5em;
  transition: opacity .25s linear, visibility 0s linear
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s
}

.ol-zoom-extent {
  bottom: 4.643em;
  right: .5em
}

/* .ol-full-screen {
  right: .5em;
  top: .5em
} */

.ol-full-screen {
  right: .5em;
  top: calc(100% - 2.375em);
}

@media print {
  .ol-control {
    display: none
  }
}

.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: #fff;
  font-size: 1.14em;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: .4em;
  background-color: #7b98bc;
  background-color: rgba(0, 60, 136, .5);
  border: none;
  border-radius: 2px
}

.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0
}

.ol-zoom-extent button {
  line-height: 1.4em
}

.ol-compass {
  display: block;
  font-weight: 400;
  font-size: 1.2em;
  will-change: transform
}

.ol-touch .ol-control button {
  font-size: 1.5em
}

.ol-touch .ol-zoom-extent {
  bottom: 5.5em
}

.ol-control button:focus,
.ol-control button:hover {
  text-decoration: none;
  background-color: #4c6079;
  background-color: rgba(0, 60, 136, .7)
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px
}

.ol-attribution {
  text-align: right;
  bottom: .5em;
  right: .5em;
  max-width: calc(100% - 1.3em)
}

.ol-attribution ul {
  margin: 0;
  padding: 0 .5em;
  font-size: .7rem;
  line-height: 1.375em;
  color: #000;
  text-shadow: 0 0 2px #fff
}

.ol-attribution li {
  display: inline;
  list-style: none;
  line-height: inherit
}

.ol-attribution li:not(:last-child):after {
  content: " "
}

.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle
}

.ol-attribution button,
.ol-attribution ul {
  display: inline-block
}

.ol-attribution.ol-collapsed ul {
  display: none
}

.ol-attribution.ol-logo-only ul {
  display: block
}

.ol-attribution:not(.ol-collapsed) {
  background: rgba(255, 255, 255, .8)
}

.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0;
  height: 1.1em;
  line-height: 1em
}

.ol-attribution.ol-logo-only {
  background: 0 0;
  bottom: .4em;
  height: 1.1em;
  line-height: 1em
}

.ol-attribution.ol-uncollapsible img {
  margin-top: -.2em;
  max-height: 1.6em
}

.ol-attribution.ol-logo-only button,
.ol-attribution.ol-uncollapsible button {
  display: none
}

.ol-zoomslider {
  bottom: 4.5em;
  right: .5em;
  height: 200px
}

.ol-zoomslider button {
  position: relative;
  height: 10px
}

.ol-touch .ol-zoomslider {
  bottom: 5.5em
}

.ol-overviewmap {
  left: .5em;
  bottom: .5em
}

.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: 0 4px 0 0
}

.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: inline-block
}

.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid #7b98bc;
  height: 150px;
  margin: 2px;
  width: 150px
}

.ol-overviewmap:not(.ol-collapsed) button {
  bottom: 1px;
  left: 2px;
  position: absolute
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none
}

.ol-overviewmap:not(.ol-collapsed) {
  background: rgba(255, 255, 255, .8)
}

.ol-overviewmap-box {
  border: 2px dotted rgba(0, 60, 136, .7)
}

.ol-viewport {
  border-radius: 4px;
}

.ol-mouse-position {
  top: auto;
  //right: auto;
  bottom: 8em;
  right: 0.5em;
  background-color: beige;
}


.change-infop {
  top: 4.5em;
  right: .5em;
}
.change-measurep {
  top: 6.2em;
  right: .5em;
}

.change-pointer {
  top: 7.9em;
  right: .5em;
}

/* further away from tools icons */
.change-erasemeasurep {
  top: 10.6em;
  right: .5em;  
}

.change-purchasep {
    top: 12.3em;
    right: .5em;
}

.change-guidep {
    top: 14em;
    right: .5em;
}

.change-nlumplegendp {
    top: 14em;
    right: .5em;
}

.ol-touch .change-infop {
  top: 5.0em;
}

.ol-touch .change-measurep {
  top: 6.7em;
}

.ol-touch .change-pointer {
  top: 8.4em;
}

.ol-touch .change-erasemeasurep {
  top: 11.1em;
}

.ol-touch .change-purchasep {
    top: 12.8em;
}

.ol-touch .change-guidep {
    top: 14.5em;
}

.ol-touch .change-nlumplegendp {
    top: 14.5em;
}

/*** custom checkboxes ***/

.special_check_box {
  display: none;
}

/* to hide the checkbox itself */
.special_check_box+label:before {
  font-family: "Font Awesome 5 Free";
  display: inline-block;
}

.special_check_box+label:before {
  content: "\f070";
}

/* unchecked icon */
.special_check_box+label:before {
  font-size: 13px;
}

.special_check_box:checked+label:before {
  content: "\f06e";
}

/* checked icon */
.special_check_box:checked+label:before {
  font-size: 14px;
}


// eye open "\f06e"
// eye close "\f070"

/*
.special_check_box {
  display:none;
  position:relative;
}

.special_check_box + label{
  background:url('images/check-box.png') no-repeat; 
  height: 16px;
  width: 16px;
  display:inline-block;
  padding: 0 0 0 0px;
}

.special_check_box:checked + label{
  background:url('images/check-box-checked.png') no-repeat;
  height: 16px;
  width: 16px;
  display:inline-block;
  padding: 0 0 0 0px;
}
*/

#mouse-position {
  //position: absolute;
  //top:  10px;
  //left: 100px;
  width: 220px;
  background-color: white;
  border-radius: 2px 2px 2px 2px;
  color: black;
  padding: 0.45em;
}

/* .custom-mouse-position {
  position: absolute;
  top:  10px;
  left: 200px;  
  width: 200px;
  background-color: white;
  border-radius: 2px 2px 2px 2px;
  color: black;
  padding: 0.45em;
} */

.collapse-function-panel {
  left: 475px;
  top: 16px;
  height: 7vh;
  width: 4vh;
  padding: 0rem;
  color: white;
  background-color: #003632;
  border-radius: 0 2px 2px 0;
  opacity: 1;
}

.expand-function-panel {
  left: 0px;
  top: 16px;
  height: 7vh;
  width: 4vh;
  padding: 0rem;
  color: white;
  background-color: #003632;
  border-radius: 0 2px 2px 0;
  display: none;
}

.topleftfixed {
  position: absolute !important;
  top: 5px;
  left: 5px;
  z-index: 100;
}

.bigmenu-icon {
  color: gold !important;
}

.topmenu-icon {
  color: #ffffff !important;
}

a:hover {
  cursor: pointer;
}

/* .bg-img {
  background: url(../images/map.jpg) center;
  background-size: cover;
  height: 100vh;
} */

.fixed-map-container {
  height: calc(100vh - 48px);
}

/*nav*/
.nav-container {
  background-color: #fcfcf5;
  /* very light beige color */
  height: calc(100vh - 48px);
  /* minus away the top info bar */
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav-container-rad {
  background-color: #fcfcf5;
  /* very light beige color */
  height: calc(100vh - 48px);
  /* minus away the top info bar */
  overflow-y: scroll;
  overflow-x: hidden;
}

//IPAD Sized
@media only screen and (min-width:768px) and (max-width: 1000px) {
    .top-menu {
        background-color: white;
        border-radius: 3px;
        padding: 0.4rem !important;
    }

    .btn, .table-dark th, .table-dark td, .table-dark thead th {
        font-size: 0.8rem;
    }

    .nlump-modal {
        padding-top: 50px !important;
    }

    .ol-touch .ol-control button {
        font-size: 1.7em;
    }

    .ol-zoom {
        top: 3.6em !important;
        right: .5em !important;
    }

    .ol-scale-line {
        bottom: 3em;
        right: 3.5em;
    }

    .change-scalep {
        top: 0.6em;
        right: .5em;
    }

    .change-infop {
        top: 8.5em;
        right: .5em;
    }
    .change-measurep {
        top: 11.5em;
        right: .5em;
    }

    .change-pointer {
        top: 14.5em;
        right: .5em;
    }

    /* further away from tools icons */
    .change-erasemeasurep {
        top: 17.8em;
        right: .5em;  
    }

    .change-purchasep {
        top: 20.8em;
        right: .5em;
    }

    .change-guidep {
        top: 23.8em;
        right: .5em;
    }

    .change-nlumplegendp {
        top: 23.8em;
        right: .5em;
    }

    .ol-touch .change-scalep {
        top: 0.8em;
    }

    .ol-touch .change-infop {
        top: 9.0em;
    }

    .ol-touch .change-measurep {
        top: 12.0em;
    }

    .ol-touch .change-pointer {
        top: 15.0em;
    }

    .ol-touch .change-erasemeasurep {
        top: 18.3em;
    }

    .ol-touch .change-purchasep {
        top: 21.3em;
    }

    .ol-touch .change-guidep {
        top: 24.3em;
    }

    .ol-touch .change-nlumplegendp {
        top: 24.3em;
    }
}

@media only screen and (max-width: 480px) {

    /* For mobile phones: */
    .gp-title {
        font-size: 0.7em;
    }

    .top-menu {
        background-color: white;
        border-radius: 3px;
        padding: 0.1rem !important;
    }

    .mobile-css {
        font-size: 0.6rem !important;
    }

    .form-control {
        height: calc(1.8em + 0.5rem + 0.5px);
        font-size: 0.6em;
        padding: 0.175rem 0.75rem;
    }

    .topleftfixed {
        top: 1px;
    }

    .col-form-label {
        padding-top: 0px;
    }
    
    .py-2 {
        padding-bottom: 0.3rem !important;
        padding-top: 0.3rem !important;
    }

    .ember-light-table {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }

    .ember-light-table .lt-column {
        font-size: 1em;
    }

    .ember-light-table .lt-row td {
        font-size: 1em;
    }

    /* unchecked icon */
    .special_check_box+label:before {
        font-size: 7px;
    }
    
    /* checked icon */
    .special_check_box:checked+label:before {
        font-size: 8px;
    }

    .nav-container {
        background-color: #fcfcf5;
        /* very light beige color */
        height: calc(100vh - 0px);
        /* for mobile because of nice scroll and limited space no point to minus */
    }

    .btn, .table-dark th, .table-dark td, .table-dark thead th {
        font-size: 0.7rem;
    }

    .nlump-modal {
        padding-top: 50px !important;
    }

    .nlump-modal-content {
        width: 95% !important;
    }

    .scale-modal {
        padding-top: 50px !important;
    }

    .scale-modal-content {
        width: 95% !important;
    }

    .ol-touch .ol-control button {
        font-size: 1.3em;
    }

    .ol-zoom {
        top: 3.0em !important;
        right: .5em !important;
    }

    .ol-scale-line {
        bottom: 3em;
        right: 3.5em;
    }

    .change-scalep {
        top: 0.6em;
        right: .5em;
    }
    
    .change-infop {
        top: 7.3em;
        right: .5em;
    }
    .change-measurep {
        top: 9.6em;
        right: .5em;
    }

    .change-pointer {
        top: 11.9em;
        right: .5em;
    }

    /* further away from tools icons */
    .change-erasemeasurep {
        top: 14.6em;
        right: .5em;  
    }

    .change-purchasep {
        top: 16.9em;
        right: .5em;
    }

    .change-guidep {
        top: 19.2em;
        right: .5em;
    }

    .change-nlumplegendp {
        top: 19.2em;
        right: .5em;
    }

    .ol-touch .change-scalep {
        top: 0.8em;
    }
    
    .ol-touch .change-infop {
        top: 7.5em;
    }

    .ol-touch .change-measurep {
        top: 9.8em;
    }

    .ol-touch .change-pointer {
        top: 12.1em;
    }

    .ol-touch .change-erasemeasurep {
        top: 14.8em;
    }

    .ol-touch .change-purchasep {
        top: 17.1em;
    }

    .ol-touch .change-guidep {
        top: 19.4em;
    }

    .ol-touch .change-nlumplegendp {
        top: 19.4em;
    }
}

.nav {
  border-bottom: 1px solid #cececead;
}

.nav-link {
  color: #666666 !important;
}

.nav-link.active {
  color: #003632 !important;
  border-bottom: 3px solid #003632;
}

.right-icon-inactive {
  background-color: #F43434;
  border-radius: 40%;
  padding: 8px;
  color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.right-icon {
  background-color: white;
  border-radius: 40%;
  padding: 8px;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.right-icon-lock {
  background-color: grey;
  border-radius: 40%;
  padding: 8px;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.nav-content-container {
  //overflow-y: scroll; //take up all space on left
  max-height: 55vh;
}

.tab-content-container .tab-pane {
  display: block;

}

.nav-content-container::webkit-scrollbar {
  display: none;
}

.nav-content {
  border-right: 3px solid transparent;
}

.nav-content:hover {
  background-color: #f2f2f2;
  border-right: 3px solid #003632;
}

.nav-content.active {
  background-color: #f2f2f2;
  border-right: 3px solid #003632;
}

.nav-note {
  font-size: 12px;
  color: red;
}

/*nav button hide/show*/
.nav-button {
  height: 7vh;
  width: 4vh;
  padding: 0rem;
  margin-top: 16px;
  background-color: #003632;
  border-radius: 0 2px 2px 0;
}

/*end nav*/

.badge-color {
  color: #fff;
  background-color: #003632;
}

/*input nav*/
.input-navigation {
  //left: 30px; // hc
  // width: 100vw;
  background-color: rgba(0, 54, 50, 0.8);
  //border-radius: 5px;
}

.input-navigation-rad {
  //left: 30px; // hc
  width: calc(100vw - 16.6666666667vw - 48px - 70px);
  // background-color: rgba(0, 54, 50, 0.8);
  background-color: rgb(245, 246, 248);
  //border-radius: 5px;
}


/*master plan nav*/
.text-white {
  color: white;
}

.text-green {
  color: green;
}

/*
.text-white,
label {
  color: white;
} */

/*float menu on the right*/
/*top menu*/
.top-menu {
  background-color: white;
  border-radius: 3px;
  padding: 0.4rem;
}

/*bottom menu*/
.bottom-menu {
  background-color: white;
  border-radius: 5px;
  padding: 15px;
}

/*end float menu*/

.footer {
  margin: 10px;
}

.bottom-note {
  font-size: 12px;
  color: white;
}

.shadow {
  box-shadow: .3rem .3rem .2rem rgba(0, 0, 0, .15) !important
}

.popovbody {
  height: 30vh;
  overflow-y: scroll;
  scrollbar-width: thin; // for firefox 
}

/*custom scroll bar*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* might need to comment this out for RAD */
// @import "ember-power-select";


/* .ember-light-table {
  background-color: #fcfcf5;
} */




.nlump-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 6; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .nlump-modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  /* The Close Button */
  .nlump-close {
    color: grey;
    // padding-top: 8px;
    float: right;
    // font-size: 25px;
    font-weight: bold;
  }
  
  .nlump-close:hover,
  .nlump-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .nlump-modal-header {
    // padding: 2px 16px;
    // background-color: white;
    // border-radius: 10px;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    font-weight: 500;
  }
  
  .nlump-modal-body {padding: 2px 16px 15px;}

  .nlump-modal-body .btn {
    border: 3px solid;
    background-color: white;
    color: grey;
    padding: 5px;
    font-size: 0.7em;
    cursor: pointer;
    font-weight: bold;
  }

  .nlump-modal-body p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .nlump-modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }

  

//Modal CSS for Scale selection
  .scale-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 6; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  
  /* Modal Content */
  .scale-modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  
  /* The Close Button */
  .scale-close {
    color: grey;
    // padding-top: 8px;
    float: right;
    // font-size: 25px;
    font-weight: bold;
  }
  
  .scale-close:hover,
  .scale-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .scale-modal-header {
    // padding: 2px 16px;
    // background-color: white;
    // border-radius: 10px;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    font-weight: 500;
  }
  
  .scale-modal-body {padding: 2px 16px 15px;}
  
  .scale-modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }
